/* ---------------------------------------------------
    SHAHMEER WRITTEN SCSS
----------------------------------------------------- */

/* ---------------------------------------------------
    HELPERS SCSS
----------------------------------------------------- */
@import "./helpers/color-scheme.scss";
@import "./helpers/box-shadow.scss";
@import "./helpers/font-size.scss";
@import "./helpers/spacing.scss";
@import "./helpers/width.scss";
@import "./helpers/height.scss";
@import "./helpers/opacity.scss";
@import "./helpers/border.scss";
@import "./helpers/z-index.scss";
@import "./component/agencyInvite.scss";
@import "./helpers/flex-layout.scss";
@import "./helpers/text-style.scss";
@import "./helpers/padding.scss";
@import "./helpers/absolute-spacing.scss";
@import "./helpers/margin.scss";
@import "./helpers/line-height.scss";

/* ---------------------------------------------------
    PAGES SCSS
----------------------------------------------------- */
// @import "./component/AutoComp.scss";
@import "./pages//vipClub.scss";
@import "./pages/main.scss";
@import "./pages/growthtool.scss";
@import "./pages/side-stepper.scss";
@import "./pages/dashboard.scss";
@import "./pages/walletscan.scss";
@import "./pages/programs.scss";
@import "./pages/referral/referral.scss";
@import "./pages/Domain/Domain.scss";
@import "./pages/automation.scss";
@import "./pages/setting/setting.scss";
@import "./pages/userprofile/userprofile.scss";
@import "./pages/auth/auth.scss";
@import "./pages/auth/brand-onboarding.scss";
@import "./pages/loyaltysuite/loyalty.scss";
@import "./pages/design/design.scss";
@import "./pages/communication/communication.scss";
@import "./pages/campaigns.scss";
@import "./pages/templates/templates.scss";
@import "./pages/public/landingPage.scss";
@import "./pages/public/popUp.scss";
@import "./pages/public/pass.scss";
@import "./pages/public/Tier.scss";

@import "./pages/venue.scss";
@import "./pages/tier.scss";
@import "./pages/inventory.scss";
@import "./pages/giveaway.scss";
@import "./pages/publicpages.scss";
@import "./pages/newdealpage.scss";
@import "./pages/ql.scss";

/* ---------------------------------------------------
SubPages
----------------------------------------------------- */

@import "./pages/redemption/subpages/redemption-create.scss";
@import "./pages/referral/subpages/analytics.scss";
@import "./pages/referral/subpages/participations.scss";

/* ---------------------------------------------------
    Components SCSS
----------------------------------------------------- */
@import "./component/WMB.scss";
@import "./component/sidebar.scss";
@import "./component/header.scss";
@import "./component/email-verify.scss";
@import "./component/cards.scss";
@import "./component/signup-header.scss";
@import "./component/referral-header.scss";
@import "./component/tables.scss";
@import "./component/sidebar-with-buttons.scss";
@import "./component/custom-slider.scss";
@import "./component/setting-header.scss";
@import "./component//brands-dropdown.scss";
@import "./component/toaster.scss";
@import "./component/customtiercard.scss";
@import "./component/custompass.scss";
@import "./component/imagedropbox.scss";
@import "./component/designdropdown.scss";
@import "./component/viewdetail.scss";
@import "./component/tagtextarea.scss";
@import "./component/fit-content-input.scss";
@import "./component/active-status.scss";
@import "./component/copy-text.scss";
@import "./component/draggable.scss";
@import "./component/customimagedrop.scss";
@import "./component/customtexteditor.scss";
@import "./component/customselect.scss";
@import "./component/button.scss";
@import "./component/radio.scss";
@import "./component/customtablev2.scss";
@import "./component/customscrollcomponent.scss";


@import "react-datepicker/dist/react-datepicker.css";

//Global
p {
  margin: 0px;
}

body {

  -webkit-font-smoothing: antialiased;
}


.imgcardD{
  box-shadow: none;
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

#Lloader{
  /* HTML: <div class="loader"></div> */
.loader {
  width: 50px;
  aspect-ratio: 1;
  display:grid;
  -webkit-mask: conic-gradient(from 15deg,#0000,#000);
  animation: l26 1s infinite steps(12);
}
.loader,
.loader:before,
.loader:after{
  background:
    radial-gradient(closest-side at 50% 12.5%,
     color(primary100) 96%,#0000) 50% 0/20% 80% repeat-y,
    radial-gradient(closest-side at 12.5% 50%,
    color(primary100) 96%,#0000) 0 50%/80% 20% repeat-x;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  transform: rotate(30deg);
}
.loader:after {
  transform: rotate(60deg);
}

@keyframes l26 {
  100% {transform:rotate(1turn)}
}
}






#Null{
  .container {
    text-align: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo img {
    width: 100px;
    height: auto;
  }
  
  nav button {
    padding: 10px 20px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .image-container img {
    max-width: 100%;
  }
  
  /* Mobile responsiveness */
  @media (max-width: 768px) {
    header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    nav {
      margin-top: 10px;
    }
  }
}


.bordernone{
  border: none !important;
}

.imgcard{
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
  cursor: pointer;
}

.imgcard:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

#midloader{
  .loader {
    height: 50px;
    aspect-ratio: 2;
    background:
      radial-gradient(farthest-side,#000 15%,#0000 18%)0 0/50% 100%,
      radial-gradient(50% 100% at 50% 160%,#E5E8F5 95%,#0000) 0 0   /50% 50%,
      radial-gradient(50% 100% at 50% -60%,#E5E8F5 95%,#0000) 0 100%/50% 50%;
    background-repeat: repeat-x;
    -webkit-mask: radial-gradient(50% 100%,#000 95%,#0000) 0 100%/50% 0% repeat-x;
    animation: l3 1s infinite alternate ease-in;
  }
  @keyframes l3 {
    0%,
    70% {-webkit-mask-size:50% 100%}
    85% {-webkit-mask-size:50% 0}
    100% {-webkit-mask-size:50% 100%}
  }
}

#HomeBox{
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
  }
  
  .grid-item {
    background-color: white;
    border-radius: 8px;
    border: 0.5px solid #D6DAE9;
    padding: 20px;
    // text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .grid-item h3 {
    margin: 0;
    font-size: 18px;
    color: #555;
  }
  
  .grid-item p {
    font-size: 24px;
    font-weight: bold;
    // margin: 10px 0;
  }
  
  .grid-item .change {
    font-size: 16px;
    padding: 5px 10px;
    border-radius: 15px;
    display: inline-block;
    height: fit-content;
  }
  
  .grid-item .change.negative {
    color: #dc3545; /* Red text */
    background-color: #fce8e6; /* Light red background */
  }
  
  .grid-item .change.positive {
    color: #28a745; /* Green text */
    background-color: #e6f9ec; /* Light green background */
  }
  
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    // padding: 20px;
  }
  
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 480px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }
}

.ellipsis-multiple-lines {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.point-two {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  letter-spacing: 0.1px;

  color: #84889B;


}

.for-fontt {

  font-size: 14px !important;

}

.text-iconn:hover {

  cursor: pointer;
}

.earning-desc {

  color: #B2B7CA;
  /* Utilities/Button/U14M -14px Medium */
  font-size: 14px;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

#b-wallet {

  .MuiFormGroup-root {
    display: flex !important;
    flex-direction: row;
    gap: 40px !important;
  }

}

#info-main {
  .auth-form {
    gap: 0px
  }
}

.shadow-info {

  box-shadow: 0 12px 48px rgba(26, 33, 52, .11);
  padding: 32px;
}

.back-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */


  /* neutral80 */

  color: #6A93FC;
}

#inStore-radio2 {

  .MuiFormGroup-root {
    display: flex !important;
    flex-direction: row;
    gap: 50px !important;
  }
}

#inStore-radio {
  .MuiFormGroup-root {
    display: flex !important;
    flex-direction: row;
    gap: 50px !important;
  }
}

.MuiBox-root {
  width: 100% !important;

}

// #Addrewards {
//   .gg {
//     overflow-y: scroll;
//   }
// }

.rewards-scroll {
  overflow-y: scroll !important;
}

// .for-wid {
//   min-width: fit-content !important;
// }

// .for-w {
//   padding: 0 !important;
// }

.evaluate {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;

  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */


  color: #2E3039;
}


.trigger1 {
  padding-top: 11px;
  border: 2px;
  text-align: center;
  border-color: rgba(0, 132, 255, .21);
  border-style: dashed;
  font-size: 14px;
  border-radius: 6px;
  color: #0084ff;
  cursor: pointer;
}

.outline-none {
  outline: none !important;
}

.hover-none {
  background-color: inherit !important;
}

.trigger1:hover {
  border-color: #0084ff;
  background-color: #0084ff0f !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.trigger2 {
  padding-top: 11px;
  border: 2px;
  text-align: center;
  border-color: #afead0;
  border-style: dashed;
  font-size: 14px;
  border-radius: 6px;
  color: #5cd59d;
  cursor: pointer;
}

.dropDownActions {
  padding: 6px 8px;
  height: 268px;
  overflow-y: scroll;
  background: #FCFDFD;
  border: 0.5px solid #D6DAE9;
  // box-shadow: 0px 3px 6px rgba(15, 15, 15, 0.1), 0px 9px 24px rgba(15, 15, 15, 0.2);
  border-radius: 4px;
}

.trigger2:hover {
  border-color: #5cd59d;
  background-color: #e0f7e3 !important;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.or {
  position: absolute;
  right: 385px;
  top: 80px;
}

.overflow-scroll {
  overflow: scroll;
}


//Empty State
.emptyState {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 12%;
  align-items: center;
}

.emptyState-sm {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}

.emptyImage {
  height: 80px;
  width: 80px;
}

.emptyImage-sm {
  height: 48px;
  width: 48px;
}

.emptyBody {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.border-outline {
  border: none;
}

.place-self-end {
  place-self: end;
}

.display {
  display: unset !important;
}

.place-self-center {
  place-self: center;
}

.d-none {
  display: none !important;
}

.text-end {
  text-align: end;
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line-height-10 {
  line-height: 10px !important;
}

.headerCollapseIcon:hover {
  svg rect {
    fill: color(primary50);
  }
}

.float-left {
  float: left;
}

.align-self-center {
  align-self: center;
}


.absoluteMiddle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.secondaryButton {
  background-color: color(white) !important;
  color: color(neutral100) !important;
  border: 1px solid #dfe1e6 !important;
}

.w-fit {
  width: fit-content !important;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.cp {
  cursor: pointer;
}

.cd {
  cursor: default;
}

.cna {
  cursor: not-allowed;
}

.cn {
  cursor: not-allowed;
}

.bg-transparent {
  background: transparent;
}

.underline {
  text-decoration: underline;
}

// .code-onboard{


// }

.color-inherit {
  color: inherit !important;
}

.justify-content-end {
  justify-content: end;
}

#loyalty-builder-switch {

  margin-right: 0px;
  margin-left: 0px;
}

// #custom-select-staff {


//   z-index: 2000;
// }

.ellipsis-2-line {

  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 234px;
  -webkit-line-clamp: 3;
  /* display: -webkit-box; */
  -webkit-box-orient: vertical;
}

.justify-content-center {
  justify-content: center;
}

.disabledbutton {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.align-items-flex-start {
  align-items: flex-start;
}

.rotate {
  transition: all ease-in-out 0.3s;

}

.rotate-top {
  transform: rotate(180deg);
  transition: all ease-in-out 0.3s;
}

.rotate-right {
  transform: rotate(270deg);
}

.rotate-left {
  transform: rotate(90deg);
}

.rotate-bottom {
  transform: rotate(0deg);
}

.flip {
  transform: scale(-1, 1);
}

.disabled {
  cursor: default;
  pointer-events: none !important;
  opacity: 0.3;
}

.disabled-high-opacity {
  cursor: default;
  pointer-events: none !important;
  opacity: 0.7 !important;
}

.enabled {
  cursor: pointer;
  pointer-events: all;
  opacity: 1;
}

.position-unset {
  position: unset !important;
}

.text-dec-none {
  text-decoration: none !important;
}

.cke_bottom {
  display: none !important;
}

.pe-none {
  pointer-events: none;
}

.bg-color-inherit {
  background-color: inherit !important;
}

a.hover-none:hover {
  color: inherit;
}

.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  top: 0;
  transition: all 0.5s ease-in-out;
}

.overflow-hidden {
  overflow: hidden;
}

.xl {
  padding: 16px 24px !important;
}

.l {
  padding: 12px 24px !important;
}

.m {
  padding: 8px 24px !important;
}

.s {
  padding: 6px 24px !important;
}

.tableRowHover:hover {
  background-color: color(neutral0);
}

//LogoUploader
.imageCover {
  height: 80px;
  width: 163px;
  object-fit: cover;
}

.display-none {
  display: none !important;
}

.content {
  width: 100%;
  // overflow-y    : scroll;
  // height: 100vh;
  z-index: 0;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: hidden;
  background: color(white);
  border-left: 0.5px solid color(neutral0);
  // border-radius : 24px 0px 0px 24px;
}

.align-self {
  align-self: center;
}

.dropdown-box {
  border: 1px solid color(neutral0);
  border-radius: 8px;
  position: absolute;
  background-color: white;
  z-index: 1000;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  transition: opacity ease 0.7s;
  max-height: 250px;
  overflow-y: scroll;
  z-index: 11 !important;
}

.transition {
  transition: all 0.2s ease-in-out;
}

.grayed-image {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.togglebtn {
  cursor: pointer;
}

.lineheight-0 {
  line-height: 0;
}

label {
  font-size: 16px;
}

.text-align-right {
  text-align: right;
  text-align: -webkit-right;
}

.object-fit-cover {
  object-fit: cover;
}

.text-align-left {
  text-align: left;
}

.justify-content-right {
  justify-content: right;
}

.justify-content-space-between {
  justify-content: space-between;
}

.text-align-center {
  text-align: center;
}

.position-fixed {
  position: fixed;
}

.d-grid {
  display: grid;
}

.grid-auto {
  grid-template-columns: auto auto auto;
}

.word-break {
  word-break: break-word;
}

.overflow-wrap-anywhere {
  overflow: hidden;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.white-space-pre {
  white-space: pre;
}

.align-items-right {
  align-items: flex-end;
}

.align-items-left {
  align-items: flex-start;
}

.l-0 {
  left: 0;
}

.b-0 {
  bottom: 0;
}

.bar {
  // border-bottom: 0.5px solid color(neutral10) !important;
  border-bottom: 0.5px solid color(neutral20) !important;
  width: 100%;
  z-index: 0;
  left: 0px;
}

.saveAndCloseBtn:focus {
  outline: none;
}

.outline-none:focus-visible {
  outline: 0px !important;
  border: 0px !important;
}



.wrapRemove {

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .background-inherit {
    background-color: inherit;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.rangePoints::after {
  content: "Points";
  margin-left: 4px;
}

.UploadImage {
  border: 1px dashed color(neutral60);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  width: 144px;
  color: color(neutral60);
  font-size: 12px;
}

.space-between {
  justify-content: space-between;
}

.object-fit-contain {
  object-fit: contain !important;
}

.space-around {
  justify-content: space-around;
}

.border-none {
  border: 0px !important;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-flex-start {
  justify-content: flex-start;
}

.max-h-100 {
  max-height: 100% !important;
}

.accordinIcon {
  margin-top: -2px !important;
}

.accordionBorder {
  border: 1px solid color(neutral20);
}

.capitalize {
  text-transform: capitalize;
}

.italic {
  font-style: italic;
}

.upperCase {
  text-transform: uppercase;
}

.position-relative {
  position: relative;
}

.resize-none {
  resize: none;
}

.customLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: color(neutral80);
}

.vertical-align-super {
  vertical-align: super;
}

.vertical-align-bottom {
  vertical-align: text-bottom;
}

.customTagHeight {
  min-height: 200px;
}

.align-items-center {
  align-items: center;
}

//used to fix the shadow of sidebar, this class is added in sidebarNav class
.handleSiderBarShadow {
  z-index: 3 !important;
}

.cursor-nodrop {
  cursor: no-drop !important;
}

//customtablecomponentstyle
.tableDesign {
  border-radius: 16px;
  background-color: color(neutral0);
  padding: 16px;
}

//tab below Bar

.customTabBar {
  // border-bottom: 2px solid #F0F0F0 !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  width: 100%;
  z-index: 0;
  left: 0px;
}

.white-space-pre {
  white-space: pre;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.defaultLandingPageImage {
  height: 100vh;
  width: inherit;
}

.backgroundCover {
  background-size: cover !important;
}

.verticalEllipses {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.SimpleSelect {
  position: relative;
  border-radius: 6px;
  color: color(neutral80);
  background-color: color(white);
  border: 1px solid color(neutral20);
  font-size: 14px;
  padding: 8px 8px 10px 8px;
  cursor: pointer;
  text-transform: capitalize;
  height: 36px;
  -webkit-appearance: none !important;
  background: url("../img/dropdownIcon.png") no-repeat 95% 13px;
}

.SimpleSelect:focus,
.SimpleSelect:hover {
  border-radius: 8;
  background-color: color(white);
  border: 1px solid color(primary50);
}

.loaderOuterPage {
  height: 100%;
}

#CampaignFunctions {
  .borderSet {
    border: 1px solid color(neutral10);
  }
}

#dateFilter {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.blurBg {
  filter: blur(62px);
  -webkit-filter: blur(62px);
}

.hoverCollapse:hover {
  fill: #2f80ed;
}

.edit-border {
  border-bottom: 2px solid #e1e5ea;
  box-sizing: border-box;
}

.box-shadow {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
}

.ellipses {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fitContentInputField {
  border: none;
  padding: 2px 0px;
  background-color: transparent;
  color: #000000;
  cursor: pointer;
  width: 100%;
}

.fitContentInputField::placeholder {
  color: #000000;
  padding: 2px 0px;
}

.fitContentInputField:focus+.titleBar {
  display: none;
  transition: all 0.2s ease-in-out;
}

.titleBar {
  background-color: color(neutral20);
  border-radius: 3px;
  height: 2px;
}

.resize-none {
  resize: none;
}

.fitContentInputField:focus {
  outline: 0px;
  text-decoration: none;
}


.error {
  color: color(danger30) !important;
  text-align: left;
  margin-top: 8px;
  font-size: 12px;
  text-transform: capitalize;
  line-height: 16px;
}

.error2 {
  color: color(danger30) !important;
  text-align: left;
  margin-top: 1px;
  font-size: 12px;
  text-transform: capitalize;
  line-height: 16px;

}

.centerAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


/* ---------------------------------------------------
    Media Queries
----------------------------------------------------- */

/*  For Sidebar */
@media (max-width: 767px) {
  #sidebar {
    height: 100vh;
    min-width: 0px;
    z-index: 1;
  }

  .content {
    width: 100%;
    // padding-top   : 40px;
    // padding-left  : 28px;
    // padding-right : 28px;
    background: color(white);
    z-index: 3 !important;
    border-radius: 24px 0px 0px 24px;
  }

  .heading {
    font-size: 30px;
    font-weight: bold;
    color: color(neutral60);
  }

  .Heading1 {
    font-size: 20px;
    font-weight: bold;
    color: color(neutral90);
  }

  .Heading2 {
    font-size: 16px;
    font-weight: bold;
    color: color(neutral90);
  }

  .Heading4 {
    font-size: 10px !important;
    font-weight: 500 !important;
  }

  .paragraph {
    font-size: 10px;
    font-weight: normal;
    color: color(neutral70);
  }

  .paragraphBold {
    font-size: 10px;
    font-weight: 500;
    font-weight: normal;
    color: color(neutral70);
  }

  .paragraphsm {
    font-size: 8px;
    font-weight: normal;
    color: color(neutral80);
  }

  .widthFixLandingPage {
    width: 100% !important;
  }

  .landingPageText {
    width: 60%;
    padding: 0px 5%;
  }

  .pageRemoveFlex {
    display: unset !important;
  }
}

.p_0 {
  padding: 0px !important;
}

.imageSection {
  position: fixed;
  right: 0px;
  top: 0px;

  .sideImage {
    width: 100%;
    height: 100vh;
  }
}

/* xs */
@media (max-width: 575px) {
  body {
    font-size: 4vw;
  }

  .main-heading {
    font-size: 7vw;
  }
}

/* xs */

@media (min-width: 360px) and (max-width: 575px) {
  #LandingPageDefault {
    body {
      font-family: "" !important;
    }

    // .Heading22M {
    //   font-size: 22px;
    //   font-weight: 500;
    //   font-family: "";
    // }
    // .Heading20M {
    //   font-size: 20px;
    //   font-weight: 500;
    //   font-family: "";
    // }
    // .Heading18M {
    //   font-size: 18px;
    //   font-weight: 500;
    //   font-family: "";
    // }
    // .Heading16M {
    //   font-size: 16px;
    //   font-weight: 500;
    //   font-family: "";
    // }
    // .Link13R {
    //   font-size: 13px;
    //   font-weight: 400;
    //   font-family: "";
    // }
    // .Link13M {
    //   font-size: 13px !important;
    //   font-weight: 500 !important;
    //   font-family: "";
    // }
    // .Link14M {
    //   font-size: 14px;
    //   font-weight: 500;
    //   line-height: 16px;
    //   font-family: "";
    // }
    // .Body14R {
    //   font-size: 14px;
    //   font-weight: 400;
    //   line-height: 16px;
    //   font-family: "";
    // }
    // .Body16R {
    //   font-size: 16px;
    //   font-weight: 400;
    //   line-height: 24px;
    //   font-family: "";
    // }
    // .Caption12R {
    //   font-size: 12px;
    //   font-weight: 400;
    //   line-height: 16px;
    //   font-family: "";
    // }
    // .Caption12M {
    //   font-size: 12px;
    //   font-weight: 500;
    //   line-height: 16px;
    //   font-family: "";
    // }

    button:focus {
      outline: none;
    }

    .imageSection {
      display: none;
    }

    .inputBox {
      width: 90% !important;
    }
  }
}

@media (min-width: 576px) and (max-width: 767px) {}

/* md */
@media (min-width: 768px) and (max-width: 991px) {}

/* lg */
@media (min-width: 992px) and (max-width: 1199px) {}

/* xl */
@media (min-width: 1200px) {}

/* xxl */
@media (min-width: 1600px) {

  // #Main {
  //   height: 100vh;
  // }
  .widthFixLandingPage {
    width: 100% !important;
  }
}

//overrite table MUI
.MuiGridFilterForm-columnSelect,
.MuiGridFilterForm-operatorSelect,
.MuiGridFilterForm-filterValueInput {
  .MuiSelect-select:focus {
    background: none;
  }

  .MuiInput-underline:after {
    border-bottom: 1px solid color(primary50);
  }

  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  }

  .MuiInputLabel-formControl {
    color: color(neutral60) !important;
    font-size: 13px !important; //Link13M
    font-weight: 500 !important; //Link13M
    font-family: "Inter";
  }

  label+.MuiInput-formControl {
    color: color(neutral60) !important;
    font-size: 13px !important; //Link13R
    font-weight: 400 !important; //Link13R
    font-family: "Inter";
  }
}

.MuiDataGrid-gridMenuList {
  .MuiMenuItem-root {
    color: color(neutral60) !important;
    font-size: 13px !important; //Link13R
    font-weight: 400 !important; //Link13R
    font-family: "Inter";
  }

  .MuiListItemIcon-root {
    min-width: 30px;
  }
}

.MuiDataGrid-toolbarContainer {
  button:focus {
    outline: none;
  }
}

#imageModalV2 {
  button:focus {
    outline: none !important;
  }
}

// .reactEasyCrop_CropArea{
// color  : white !important;
// opacity: 0.5;
// box-shadow : unset !important;
// border     : none !important;
// }
// .reactEasyCrop_Container{height: 470px;}

// // crop
// .reactEasyCrop_CropArea{
//   // width     : 100% !important;
//   // height    : 100% !important;
//   left      : 0% !important;
//   top       : 0% !important;
//   transform : none !important;
// }
// .reactEasyCrop_Contain{
//   top    : -88px !important;
//   margin : 0px !important;
// }

//customAutoCompleteCheckBox in audience filter

// #CustomSelect{
.MuiListItem-root {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter";
  background-color: color(white) !important;
  color: color(neutral100) !important;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: color(white) !important;
  color: color(primary50) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter";
}

.MuiListItem-button:hover {
  background-color: color(white) !important;
  color: color(primary50) !important;
}

.text-ellipsis--2 {
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

// }

#customAutoCompleteCheckBox {
  .textField {
    max-height: fit-content;

    .MuiChip-label {
      color: color(primary50);
      font-size: 12px;
      font-weight: 500;
      font-family: "Inter";
    }

    .MuiChip-deleteIcon {
      color: color(primary50);
    }

    .MuiAutocomplete-tag {
      margin: 2px;
      background-color: color(neutral20);
    }

    .MuiChip-root {
      padding: 1px;
    }
  }

  .MuiAutocomplete-inputRoot:focus-visible {
    outline: none !important;
  }

  .MuiAutocomplete-inputRoot:focus {
    outline: none !important;
    // border: 0px !important;
  }

  .MuiFormControl-root:focus-visible {
    outline: none !important;
  }

  .MuiOutlinedInput-notchedOutline:focus-visible {
    outline: none !important;
  }

  .MuiAutocomplete-inputFocused:focus-visible {
    outline: none !important;
  }

  .MuiAutocomplete-root:focus-visible {
    outline: none !important;
  }

  #customAutoCompleteCheckBox::placeholder {
    font-size: 12px;
    color: color(neutral100) !important;
    font-weight: 400 !important;
  }
}

#customRefresh {
  .imageButton {
    border-radius: 50%;
  }

  .imageButton:hover {
    box-shadow: 0px 0px 0px 7px #f0f1f3;
    background-color: #f0f1f3;
    border-radius: 50%;
    transition: box-shadow 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  }
}

//Filter Component for tables

#filterComponentAudience {
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100%;
  padding: 16px;
  border-radius: 6px;
  background-color: white;
  z-index: 10;
  box-shadow: -4.08333px -4.08333px 12.25px #f0f1f5,
    6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4),
    inset 0px 0px 0px 1px rgba(240, 241, 245, 0.2);

  .activeBox {
    padding: 8px;
    border-radius: 6px;
    background-color: color(neutral0);
    margin-right: 8px;
  }

  .deactiveBox {
    padding: 8px;
    border-radius: 6px;
    background-color: color(neutral0);
  }

  .isActiveDate,
  .isActiveDate:hover {
    border: 1px solid color(primary50) !important;
  }

  .activeBox:hover,
  .deactiveBox:hover {
    color: color(primary50) !important;
    background-color: color(neutral20) !important;
  }

  .selectedBox {
    color: color(primary50) !important;
    outline: 2px solid color(primary50);
    transition: ease 0.1s;
    background-color: color(neutral20);
  }

  .subscribedDate {
    border-radius: 4px;
    width: 100%;
    padding: 8px 16px;
    border: 1px solid color(neutral20);
  }

  .dateBox {
    z-index: 1000;
  }
}

//FLOW
#Flow {
  .react-flow__node-default {
    width: fit-content;
    opacity: unset !important;
  }

  .react-flow__node-output {
    opacity: unset !important;
  }

  .react-flow__node-input {
    opacity: unset !important;
  }

  .react-flow__edge-path {
    width: fit-content;
    opacity: unset !important;
    fill: none;
    stroke: #b1b1b7;
    stroke-width: 1;
  }
}

#Deal,
#Listing {
  #CustomTableV2 {
    max-height: fit-content !important;
    overflow-y: scroll;

    .MuiDataGrid-dataContainer {
      height: 100%; //safari fix
    }

    .MuiDataGrid-renderingZone {
      max-height: fit-content !important;
      height: 100% !important; //safarifix
    }

    // .MuiDataGrid-dataContainer{
    //   height: 100% !important;
    // }
    .MuiDataGrid-root .MuiDataGrid-cell {
      // max-height    : fit-content !important;
      white-space: unset !important;
      line-height: 16px !important;
      padding-top: 14px;
      padding-bottom: 14px;
      padding: 0px 24px !important;
      height: inherit !important;
      max-height: inherit !important;
      display: flex;
      align-items: center;
      min-height: 90px !important;
    }

    .MuiDataGrid-root .MuiDataGrid-row {
      max-height: fit-content !important;
      height: 90px;
    }

    .MuiDataGrid-root .MuiDataGrid-viewport {
      max-height: fit-content !important;
      height: 100% !important; //safari fix
    }

    .MuiDataGrid-root .MuiDataGrid-cell--textRight.MuiDataGrid-cell--withRenderer,
    .MuiDataGrid-root .MuiDataGrid-cell--textRight.MuiDataGrid-cell--editing {
      align-items: unset !important;
    }

    .MuiDataGrid-root .MuiDataGrid-columnHeader {
      padding: 0px 24px !important;
    }

    // .MuiDataGrid-root .MuiDataGrid-cell--withRenderer{
    // align-items : unset !important;
    // }
  }
}

#FlowBuilder {

  // margin-top: -30px;
  button:focus {
    outline: none !important;
  }

  .react-flow__node-input.selected,
  .react-flow__node-input.selected:hover,
  .react-flow__node-default.selected,
  .react-flow__node-default.selected:hover {
    outline: 2px solid color(primary50) !important;
    box-shadow: 0px 8px 20px rgba(4, 24, 62, 0.08) !important;
    border-radius: 8px !important;
  }

  .react-flow__handle.connectable {
    height: 10px !important;
    width: 10px !important;
    background-color: color(primary50);
    // height           : 16px !important;
    // width            : -webkit-fill-available !important;
    // background-color : transparent;
    // border           : none;
    // border-radius    : 0;
  }

  .react-flow__controls {
    // left: 80px !important;
    left: 380px;
  }

  // #mySidebar{
  .xyz {
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;
    width: 300px;
    margin-left: -40px;
    background-color: #fff;
    border-right: 1px solid #e8e8ef;
    box-sizing: border-box;
    position: absolute;
    z-index: 10;
    height: calc(100vh - 84px) !important;

    button: focus {
      outline: none !important;
    }
  }

  .abc {
    width: 363px;
    background-color: #fff;
    border-left: 1px solid #e8e8ef;
    box-sizing: border-box;
    position: absolute;
    right: 0px;
    // top              : 58px;
    // top              : 0px;
    z-index: 4;
  }

  .rightCollapse {
    transition: all 0.3s ease-in-out;
    text-align: -webkit-right;
    position: fixed;
    // background: color(white);
    z-index: -1;
    // padding    : 8px;
    right: 0px;
    left: 60px;
    cursor: pointer;
    top: 88px;
    width: 340px;
  }

  .sidebarHover:hover {
    box-shadow: 0px 8px 20px rgba(4, 24, 62, 0.08);
    transition: box-shadow 0.2s cubic-bezier(0.05, 0.03, 0.35, 1);
  }
}

/* Custom styles for the Cal floating button */
.cal-floating-button {
  position: fixed;
  left: 10px; /* Adjust left position if needed */
  bottom: 10px; /* 10px above the bottom */
  z-index: 9999; /* Ensure it stays on top */
}

//For tagify

// .tagify--mix .tagify__input{
// line-height: 1.px;
// }

//for book my cal
// iframe {
//   display: none;
// }

#TextfieldUpdated,
#SelectUpdated {
  textarea {
    resize: none;
  }

  input,
  textarea,
  select,
  .fitContentInputField {
    color: color(neutral100);
    border: 1px solid color(neutral20) !important;
    width: 100%;
    padding: 10px 16px;
    box-sizing: border-box;
    outline: 0px;
    letter-spacing: 0.1px;
    transition: all 0.2s ease-in;
  }

  input::placeholder,
  textarea::placeholder {
    color: color(neutral40);
  }

  select {
    -webkit-appearance: none;
    height: 40px;
    -moz-appearance: none;
    background-color: color(neutral0);
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0iIzZlNzY4MSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNC40MjcgOS40MjdsMy4zOTYgMy4zOTZhLjI1MS4yNTEgMCAwMC4zNTQgMGwzLjM5Ni0zLjM5NkEuMjUuMjUgMCAwMDExLjM5NiA5SDQuNjA0YS4yNS4yNSAwIDAwLS4xNzcuNDI3ek00LjQyMyA2LjQ3TDcuODIgMy4wNzJhLjI1LjI1IDAgMDEuMzU0IDBMMTEuNTcgNi40N2EuMjUuMjUgMCAwMS0uMTc3LjQyN0g0LjZhLjI1LjI1IDAgMDEtLjE3Ny0uNDI3eiIgLz48L3N2Zz4=") !important;
    background-repeat: no-repeat !important;
    background-position-x: 96% !important;
    padding: 8px 16px !important;
    background-position-y: 50% !important;
  }

  input[type="number"] {
    line-height: 30px;
    height: 40px;
  }

  input[type="number"]::-webkit-inner-spin-button {
    // position: absolute;
    // right: 4px;
    height: 24px;
    // width: 30px;
  }

  input:hover,
  textarea:hover,
  select:hover {
    border-color: color(primary50) !important;
  }

  // input:focus,
  // textarea:focus {
  //   margin: -1px;
  // }

  input:focus,
  textarea:focus,
  select:focus {
    background: #ffffff !important;
    border: 1px solid color(primary50) !important;
    box-shadow: inset 0px 0px 0px 2px color(primary20);
  }

  .FocusedIcon {
    color: color(primary50) !important;

    svg,
    svg path {
      color: color(primary50) !important;
      fill: color(primary50) !important;
    }
  }
}

.MuiIconButton-colorSecondary:hover {
  background-color: color(neutral20) !important;
}



.dataRow {
  height: 76px;
  cursor: pointer;
  // box-shadow : 0px 1px 0px #DFE1E6;

  .col-3 {
    align-self: center !important;
  }
}

.modalComponent {
  max-height: 78vh;
  overflow: scroll;
  padding: 2px;
}


.modalButton {
  position: sticky;
  bottom: 0px;
  background-color: white;
  border-top: 0.5px solid color(neutral20);
  padding: 24px 24px 0px 24px;
  margin-left: -24px;
  margin-right: -24px;
}

.dataRowSmall {
  height: 64px;
  cursor: pointer;
  // box-shadow : 0px 1px 0px #DFE1E6;

  .col-3 {
    align-self: center !important;
  }
}

.color-danger {
  color: #de245c;
}

//customDropDown
.customDropDown {
  height: 300px;
  overflow-y: scroll;
  word-break: break-all;
  position: absolute;
  margin-top: 4px;
  border: 0.5px solid #d6dae9;
  border-radius: 6px;
  background-color: #fcfdfd;
  box-shadow: 0px 3px 6px rgba(15, 15, 15, 0.1),
    0px 9px 24px rgba(15, 15, 15, 0.2);
  z-index: 11;
  padding-bottom: 10px;

  .searchField {
    padding: 8px;
  }

  .option {
    padding: 3.5px 4px 3.5px 4px;
    // padding        : 10px 12px;

    display: flex;
    flex-direction: row;
    cursor: pointer;
  }

  .option:hover {
    background: #f2f6ff;
    border-radius: 6px;
  }

  .options {
    margin: 4px;

    .option {
      align-items: center;

      img {
        border-radius: 50%;
      }
    }
  }
}

.customDropDownClosed {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  height: 40px;
  border: 1px solid #d6dae9;
  border-radius: 6px;
  background: #f5f7fc;
  align-items: center;
  cursor: pointer;
  padding: 10px 16px;
}

.customTagHeight {
  min-height: 112px !important;
}

.right-0 {
  right: 0;
}

.--tag-bg {
  background: #2960ec;
  border-radius: 6px;
  color: white;
}

#AddInventory2 {
  .imageUploadBox {
    height: 90px;
    // width: 163px;
    width: 90px;
    border-radius: 4px;
    background-color: #f7f7f7;
    overflow: hidden;
    border: 1px solid #dfe1e6;
  }
}

.waystoredeem:hover {
  background-color: color(neutral0);
}

#AddRedeemingWays {
  .radiotop {
    .MuiRadio-root {
      margin-top: -20px;
    }
  }
}

.more-option {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.svg-hover {
  path {
    fill: color(neutral50) !important;
    transition: all 0.1s ease-in-out !important;
  }
}

.svg-hover:hover {
  path {
    fill: color(primary50) !important;
  }
}

#DisplayFlow {
  .childBox {
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    padding: 16px 15px 16px 15px;
    gap: 15px;
    background: #FFFFFF;
    border: 1px solid #E5E8F5;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }
}

#RewardBuilder {
  // background-color: #FAFAFA;
  overflow-y: scroll;
  padding-bottom: 27% !important;

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    // background-color: inherit !important;
  }

  select::-ms-expand {
    display: none;
  }

  .unExpanded {
    max-height: 0px;
    transition: all ease-in-out 0.3s;
    overflow: hidden;
  }

  .expanded {
    max-height: 450px;
    transition: all ease-in-out 0.3s;

  }

  .dealViewIcon:hover {
    svg {
      path {
        fill: #2f80ed;
      }
    }
  }

  .input-sizer {
    display: inline-grid;

    &::after,
    input,
    textarea {
      width: auto;
      min-width: 1em;
      resize: none;
      background: none;
      appearance: none;
      border: none;
    }

    &::after {
      content: attr(data-value) ' ';
      visibility: hidden;
      white-space: pre-wrap;
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }

  input::-webkit-datetime-edit-fields-wrapper {
    text-decoration: underline;
  }

  input {
    background-color: inherit !important;
  }

  select:focus-visible {
    outline: none !important;
  }

  div:focus-visible {
    outline: none !important;
  }

  input:focus-visible {
    outline: none !important;
  }

  .focusactive {
    border-radius: 4px;
    padding: 2px;
  }

  .mainfocusactive {
    border-radius: 4px;
    padding: 2px;
  }

  .mainfocusactive:focus-within {
    background-color: color(neutral10);
    text-decoration: none !important;
    transition: all 0.2s ease-in-out;
  }

  .mainfocusactive:hover {
    background-color: color(neutral10) !important;
    text-decoration: none !important;
    transition: background-color 0.2s ease-in-out;
  }

  .focusactive:hover {
    background-color: color(neutral10) !important;
    text-decoration: none !important;
    transition: background-color 0.2s ease-in-out;
  }

  .main-block {
    border: 0.5px solid #D6DAE9;
    box-shadow: $shadow-card;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 24px;
    padding: 20px;
  }

  .main-block1 {
    border: 0.5px solid #D6DAE9;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 24px;
    padding: 20px;
    background-color: #c3ccd621;
  }

  .main-block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // height: 32px;
    width: 100%;
    padding-bottom: 12px;
  }

  .main-block-header-dropdown {
    position: absolute;
    top: 48px;
    right: 24px;
  }

  .childbox-dropdown-trigger {
    position: absolute;
    top: 40px;
    right: 24px;
  }

  .childbox-dropdown-action {
    position: absolute;
    top: 40px;
    right: 24px;
  }

  .childBoxAction {
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    padding: 16px 15px 16px 15px;
    gap: 15px;
    background: #FFFFFF;
    border: 1px solid #E5E8F5;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  .childBox {
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    padding: 16px 15px 16px 15px;
    gap: 15px;
    background: #FFFFFF;
    border: 1px solid #E5E8F5;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
  }

  .childBox:hover {
    box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);

    .showcross {
      display: flex;
    }

  }

  .childBox2:hover {
    .showcross {
      display: flex;
    }

  }

  .childBox1 {
    border: 0.5px solid #D6DAE9;
    border-radius: 4px;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
  }

  .showcross {
    display: none;
  }

  .imgBox {
    border: 0.5px solid #D6DAE9;
    // box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  select {
    text-align: center;
  }

}


#thanksPageLoaderSection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;

  .loader {
    border: 12px solid #f3f3f3;
    border-radius: 50%;
    border-top: 12px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
  }

  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}



// #VipClub{
//   height: 100vh;
//   body {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     background: #242d60;
//     font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
//     'Helvetica Neue', 'Ubuntu', sans-serif;
//     height: 100vh;
//     margin: 0;
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//   }
//   section {
//     background     : #ffffff;
//     display        : flex;
//     flex-direction : column;
//     width          : 400px;
//     height         : 160px;
//     border-radius  : 6px;
//     justify-content: space-between;
//     box-shadow     : rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
//   }
//   .product {
//     display: flex;
//   }
//   .description {
//     display: flex;
//     padding        : 32px;
//     flex-direction: column;
//     justify-content: center;
//   }
//   img {
//     border-radius: 6px;
//     margin: 10px;
//     width: 150px;
//     height: 150px;
//   }
//   h3,
//   h5 {
//     font-style: normal;
//     font-weight: 500;
//     font-size: 20px;
//     line-height: 20px;
//     letter-spacing: -0.154px;
//     color: #242d60;
//     margin: 0;
//   }
//   h5 {
//     opacity: 0.5;
//   }
//   button {
//     height: 36px;
//     background: #556cd6;
//     color: white;
//     width: 100%;
//     font-size: 14px;
//     border: 0;
//     font-weight: 500;
//     cursor: pointer;
//     letter-spacing: 0.6;
//     border-radius: 0 0 6px 6px;
//     transition: all 0.2s ease;
//     box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
//   }
//   button:hover {
//     opacity: 0.8;
//   }
// }

.w-48 {
  width: 48% !important;
}

.css-1ald77x.Mui-focused{
  font-size: 16px !important;
}

.B16R {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}

.B16M {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.B12M {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
}

.B10R {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
}

.B12R {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.B14R {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

#Transaction{
  .css-levciy-MuiTablePagination-displayedRows,
  .css-pdct74-MuiTablePagination-selectLabel, 
  .css-194a1fa-MuiSelect-select-MuiInputBase-input.MuiSelect-select,
  .css-i4bv87-MuiSvgIcon-root{
    font-family: 'Inter' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    
  }
}

.B16R {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
}
.B14M {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.B10M {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}

.subTextBox {
  border: 1px solid #D6DAE9;
  border-radius: 6px;
  padding: 7px 16px;
}


.hoverclass:hover {
  background-color: #F2F6FF;
}

.H18M {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.H16M {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.H22M {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 24px;
}

.H24M {
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}


.showArrow {
  input[type=number]::-webkit-inner-spin-button {
    opacity: 1
  }
}



.bg-color-fafafa {
  background-color: #FAFAFA;
}


.tableRowNew {
  background: #FFFFFF;
  border-bottom: 0.5px solid #D6DAE9;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  margin-bottom: 1px !important;
}

.mentions__mention {
  position: relative;
  z-index: 1;
  padding: 2px 0px;
  border-radius: 2px;
  background-color: #2960EC;
  color: white;
}










/////
.border {
  border: 1px solid #D6DAE9;
  border-radius: 6px;
}

.qrBoxOnboard {
  background: #FFFFFF;
  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

#mainSU_CU {
  background-color: #FAFAFA;
}

.border-primary50 {
  border: 1px solid color(primary50);
}

#AutocompleteLocation {
  .mdc-text-field {
    height: 50px;
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 6px;
  }

  .mdc-text-field__input {
    width: 100%;
    height: 50px;
    font-size: 16px;
    padding: 4px 0;
    background-color: transparent;
    padding: 12.5px 14px !important;
    outline: none;
    border-radius: 4px;
    border: 1px solid #c4c4c4 !important;
    box-sizing: border-box;
    padding-left: 0.2em;
  }

  .mdc-text-field__input:hover {
    border: 1px solid color(primary50) !important;
  }

  .mdc-text-field__input:focus {
    border: 2px solid color(primary50) !important;
  }

  .mdc-floating-label {
    position: absolute;
    top: 12px;
    left: 12px;
    font-size: 16px;
    padding: 0px 7px;
    font-family: Inter;
    color: #525252;
    line-height: 16px;
    transform-origin: top left;
    background-color: white;
    z-index: 10;
  }

  .mdc-text-field__input:focus+.mdc-floating-label {
    top: 0px;
    left: 10px;
    color: #525252;
    transform: scale(0.75) translateY(-48%);
  }

  .mdc-text-field__input:focus~.mdc-floating-label,
  .mdc-text-field__input:not(:placeholder-shown)~.mdc-floating-label {
    top: 0px;
    left: 10px;
    color: #525252;
    transform: scale(0.75) translateY(-48%);
  }

  .mdc-line-ripple {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: color(primary50);
    transform-origin: bottom left;
    transform: scaleX(0);
  }

  .mdc-text-field__input:focus~.mdc-line-ripple {
    transform: scaleX(1);
  }
}


.MuiFormHelperText-root {
  font-size: 10px !important;
  color: red !important;
  margin-left: 10px;
  margin-top: 0px;
}

.MuiInputBase-input {
  padding: 12.5px 14px !important;
}


.MuiMenuItem-gutters {
  font-size: 14px !important;
}

.MuiMenu-list,
.MuiMenu-list li {
  max-height: 250px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  z-index: 10000000000 !important;
  position: relative;
}

.MuiMenu-list li {
  padding: 4px 8px;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.12) !important;
}


#demo-simple-select-label {
  top: -5px;
}

.MuiInputLabel-shrink {
  top: 0px !important;

}

#PhoneInputField {
  input {

    border-color: color(neutral20) !important;
    background-color: color(neutral0);
    box-shadow: none;
  }

  .highlight {
    background: #fafbff;
  }

  .flag-dropdown {
    background-color: white;
    border: 1px solid color(neutral20);
  }

  .form-control:focus {
    background: #ffffff !important;
    box-shadow: 0px 0px 0px 2px #2960ec !important;
    transition: 0.3s all ease;
  }
}

.phoneInputField {
  border-color: color(neutral20) !important;
  background-color: color(neutral0);
  box-shadow: none;
}

.highlight {
  background: #fafbff;
}

.flag-dropdown {
  background-color: white;
  border: 1px solid color(neutral20);
}

.phoneInputField:focus {
  background: #ffffff !important;
  box-shadow: 0px 0px 0px 2px #2960ec !important;
  transition: 0.3s all ease;
}

.MuiRadio-root {
  padding: 9px !important;
}

.ql-editor {
  height: 200px !important;
}

.cc {
  position: absolute;
  top: -9px;
  left: 15px;
  background: white;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 12.5px !important;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding-left: 4px;
  padding-right: 4px;
  display: block;
}

.bg-F5F5F5 {
  background-color: #F5F5F5 !important;
}

.revL {
  animation-name: slide-in;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes slide-in {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.revLtwo {
  animation-name: slide-intwo;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes slide-intwo {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.revLthree {
  animation-name: slide-inthree;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes slide-inthree {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

.revLfour {
  animation-name: slide-infour;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes slide-infour {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

.revLfive {
  animation-name: slide-infive;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes slide-infive {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}


.MuiSwitch-switchBase {
  display: inline-flex !important;
  align-items: center !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  -webkit-justify-content: center !important;
  position: relative !important;
  box-sizing: border-box !important;
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0 !important;
  border: 0 !important;
  margin: 0 !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  vertical-align: middle !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
  color: inherit !important;
  padding: 9px !important;
  border-radius: 50% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1 !important;
  color: #fff !important;
  -webkit-transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, -webkit-transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  transition: left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #1976d2 !important;
}

#LoginBtn {
  .MuiInputBase-input {
    // padding: 12px 16px !important;
    font-size: 16px !important;
  }
  
  .css-p0rm37{
    padding: 0 !important;
    font-size: 16px !important;
  }

  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 16px !important;
    background-color: white !important;
    padding-right: 7px !important;
    margin-left: -5px;
    padding-left: 4px;
  }

  .MuiInputBase-formControl {
    height: 100%;
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline:hover {
    border-color: #0084ff !important;
  }

  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    font-size: 13px;
  }

  .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 16px !important;
  }

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {}

  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    background-color: white !important;

  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #2960ec !important;
  }

}

.MuiBox-root {
  width: 100% !important;

}

.justify-content-space-between {
  justify-content: space-between;
}

.forChildw-100 form {
  width: 100% !important;
}

.fl_r {
  float: right !important;
}

.specialchildformdivwidthremove .MuiBox-root {
  width: 28% !important;
}

.rpInput {
  padding: 6.5px 12px !important;
  border: 0.5px solid#c2c2c2;
  border-radius: 4px;
}

.childmlhandle .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  // margin-left: -9px !important;
}

.sbgc-F5F7FC {
  background-color: #F5F7FC !important;
}

.MuiInputBase-input::-webkit-calendar-picker-indicator {
  background: url(https://cdn3.iconfinder.com/data/icons/linecons-free-vector-icons-pack/32/calendar-16.png) center/80% no-repeat;
  color: black;
}

.align-items-flex-end {
  align-items: flex-end !important;
}

.hidden-scroll {
  overflow-y: scroll;
}

.hidden-scroll::-webkit-scrollbar {
  display: none;
}




@keyframes fade-in {
  0% {
    height: 0px;
    opacity: 0;
  }

  100% {
    height: 449.5px;
    opacity: 1;

  }
}

.animatpp {
  animation: fade-in 0.5s ease-in-out;
}

@keyframes fade-out {
  0% {

    height: 449.5px;
    opacity: 1;
  }

  100% {
    height: 0px;
    opacity: 0;

  }
}

.aminatezz {
  animation: fade-out 0.5s ease-in-out;
}

.logOut-onboarding {
  color: #4678F6;
  text-align: right;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.react-datepicker__input-container input {
  border: 1px solid #c1c1c2 !important;
  border-radius: 4px !important;
}

.detailsMain {
  display: flex;
  justify-content: space-between;

}

.details-title {
  color: #2E3039;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.details-value {
  color: #2E3039;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.PlusPercentage {
  color: #3DCC87;
  font-family: DM Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

.Labelzz {
  color: #141519;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.Labelzz svg {
  margin-right: 8px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.copyHead{
  
}
.copyHead{

}








/* UnsubscribeButton.css */

.unsubscribe-container {
  max-width: 400px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.unsubscribe-container h2 {
  margin-bottom: 10px;
  color: #333;
}

.unsubscribe-container p {
  margin-bottom: 20px;
  color: #666;
  font-size: 16px;
}

.unsubscribe-button {
  padding: 12px 20px;
  background-color: #d9534f;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.unsubscribe-button:hover {
  background-color: #c9302c;
}








.notiText5{
  color: #2E3039;
font-feature-settings: 'liga' off, 'clig' off;

/* Body/B14R -14px Regular */
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
letter-spacing: 0.1px;
}
.notiText4{
  margin-top: 10px;
  color: #141519;
font-feature-settings: 'liga' off, 'clig' off;

/* Heading/H15M -15px Medium */
font-family: "DM Sans";
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 133.333% */
letter-spacing: 0.1px;
}
.notiText3{
  color: #84889B;
font-feature-settings: 'liga' off, 'clig' off;

/* Body/B14R -14px Regular */
font-family: 'Inter';
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 142.857% */
letter-spacing: 0.1px;
}
.notiText2{
  color: #141519;
font-feature-settings: 'liga' off, 'clig' off;

/* Heading/H18M -18px Medium */
font-family: "DM Sans";
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
letter-spacing: 0.1px;
margin-top: 24px;
}
.notiText1{
  color: #2E3039;
  margin-top: 32px;
font-feature-settings: 'liga' off, 'clig' off;

/* Utilities/Links/L11M -11px Medium */
font-family: 'Inter';
font-size: 11px;
font-style: normal;
font-weight: 500;
line-height: 16px; /* 145.455% */
letter-spacing: 0.1px;
}



.col-end{
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.col-center{
  display: flex;
  align-items: center;
  flex-direction: column;
}


.ua-desc{
  color: #686C7C;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.ua-whitehead{
  color:  #FFF;
  font-family: Inter;
  font-size: 32px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 36px; /* 100% */
  text-transform: capitalize;
}
.ua-head1{
  color: #141519;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.112px;
}
.ua-head{
  color: #141519;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}
.ua-bg{
  border-radius: 4px;
  width: fit-content;
  border: 1px solid #C5C9DB;
  background: #E5E8F5;
  padding: 9px 17px 9px 17px;
}
.tab-text{
  // color: rgba(46, 48, 57, 0.40);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 145.455% */
  letter-spacing: 0.1px;
}







/* Mobile screen container */
.sms-container {
  width: 100%;
  max-width: 400px;
  height: 600px;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Header */
.sms-header {
  background: #007aff;
  color: white;
  padding: 10px 15px;
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Messages container */
.sms-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Message bubbles */
.sms-bubble {
  max-width: 70%;
  padding: 10px;
  border-radius: 15px;
  font-size: 14px;
  line-height: 1.4;
  word-wrap: break-word;
}

.sms-bubble.self {
  background: #007aff;
  color: white;
  align-self: flex-end;
  border-bottom-right-radius: 0;
}

.sms-bubble.other {
  background: #e5e5ea;
  color: black;
  align-self: flex-start;
  border-bottom-left-radius: 0;
}

/* Input container */
.sms-input-container {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background: white;
}

/* Input field */
.sms-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 20px;
  outline: none;
  font-size: 14px;
}

/* Send button */
.sms-send-button {
  margin-left: 10px;
  padding: 10px 15px;
  background: #007aff;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
}

.sms-send-button:hover {
  background: #005bb5;
}




#notificationsAll{
  input[type="checkbox"] {
    width: 25px; /* Adjust size as needed */
    height: 25px;
    cursor: pointer;
  }
}